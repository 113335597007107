import React from "react"
import { Link } from "gatsby"

import SecondaryLayout from "../components/layouts/SecondaryLayout"

import QuiSommesNousPage from "../containers/QuiSommesNousPage"

const SecondPage = () => {
  // or const [t, i18n] = useTranslation();

  return (
    <SecondaryLayout>
      <QuiSommesNousPage />
    </SecondaryLayout>
  )
}

export default SecondPage
