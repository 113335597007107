import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import Hero from "../components/Hero"
import Section from "../components/Section"
import Heading from "../components/Heading"

const useStyles = makeStyles(theme => ({
  informations: {
    display: "block",
    width: "100%",
    marginTop: theme.spacing(10),
    padding: theme.spacing(5),
    borderTop: "solid 6px " + theme.palette.primary.main,
    background: "#e6edf0",
    borderRadius: "10px",
  },
  image: {
    width: "100%",
    borderRadius: "20px",
  },
}))

const QuiSommesNousPage = ({ children }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      imageSection1: file(
        relativePath: { eq: "page-blocus-assiste-section-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laure: file(relativePath: { eq: "photo_laure_nb.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thierry: file(relativePath: { eq: "photo_thierry_nb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Hero
        title="A propos d’Eureka"
        minHeight="40vh"
        variant="secondary"
      ></Hero>

      <Section>
        <Grid container spacing={10}>
          <Grid item md={5} alignItems="center" container>
            <Img
              fluid={data.imageSection1.childImageSharp.fluid}
              className={classes.image}
            />
          </Grid>
          <Grid item md={7}>
            <p>
              Eureka propose des{" "}
              <strong>accompagnements personnalisés aux étudiants</strong> pour
              réussir et s’épanouir dans leurs études.
            </p>
            <p>
              Eureka a été fondé par Thierry de Merode, docteur en sciences, et
              s'est développé en une équipe multidisciplinaire spécialisée dans
              l’enseignement, le coaching scolaire et l’orientation.
            </p>
            <p>
              Parce que chaque étudiant et chaque situation sont différents,
              nous concevons toujours une approche sur mesure :{" "}
              <Link to="/coaching-scolaire">
                <strong>coaching scolaire</strong>
              </Link>{" "}
              aux{" "}
              <Link to="/cours-particuliers">
                <strong>cours particuliers</strong>
              </Link>{" "}
              en passant par des{" "}
              <Link to="/blocus-assiste">
                <strong>blocus assistés</strong>
              </Link>{" "}
              et de l'
              <Link to="/orientation-scolaire">
                <strong>orientation scolaire</strong>
              </Link>
            </p>
            <p>
              Nous sommes fiers d’être à l’écoute des besoins de chaque famille
              pour offrir un accompagnement sur mesure et complet.
            </p>
            <p>
              Notre méthode de travail associe une{" "}
              <strong>implication active de l’étudiant</strong> à un soutien
              scolaire et moral.
            </p>
            <p>
              Nous faisons toujours cela avec un objectif clair :{" "}
              <strong>faire réussir</strong> et{" "}
              <strong>s’épanouir les étudiants.</strong>
            </p>
          </Grid>
        </Grid>
      </Section>
      <Section bg="#F2F6F7">
        <Grid container spacing={10}>
          <Grid item md={7}>
            <Heading
              title="Thierry de Merode"
              subtitle="Tuteur & Coach scolaire"
            />
            <p>
              Accompagner des étudiants combine parfaitement ma passion pour
              l’enseignement et mon plaisir d’aider les autres.
            </p>
            <p>
              Voir un élève repartir confiant après une séance, recevoir un
              appel d’un étudiant qui vient de réussir son examen ou encore
              recevoir un remerciement d’un parent fier de son enfant me rendent
              chaque jour heureux.
            </p>
            <p>
              Après plus de 15 ans dans le monde de l’éducation, je suis heureux
              de lancer Eureka. Riche de mes expériences passées (assistant
              universitaire en Angleterre, professeur pour le jury central,
              professeur en humanité et en haute école, directeur de blocus chez
              nos concurrents, professeur particulier, rédaction d’une thèse,
              …), j’ai la volonté d’offrir un accompagnement aux étudiants qui
              replace le « sens » au cœur de leur étude et de leur motivation.
            </p>
            <p>Ensemble comprendre, apprendre et réussir…</p>
          </Grid>
          <Grid item md={5} alignItems="center" container>
            <Img
              fluid={data.thierry.childImageSharp.fluid}
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={10}>
          <Grid item md={5} alignItems="center" container>
            <Img
              fluid={data.laure.childImageSharp.fluid}
              className={classes.image}
            />
          </Grid>
          <Grid item md={7}>
            <Heading
              title="Laure Laroche "
              subtitle="Coach en orientation scolaire"
            />
            <p>
              Depuis bientôt 10 ans, j’accompagne des particuliers et des
              entreprises dans les domaines de la gestion du changement, du
              leadership, de l’orientation et du développement personnel.
            </p>
            <p>
              En tant que personne, je suis enthousiaste et entière. J’aime
              aborder les situations de manière pragmatique et apporter ma
              touche de légèreté. Ma curiosité fait de moi un auditeur attentif
              et bienveillant.
            </p>
            <p>
              En tant que coach, je suis guidée par la volonté de mettre les
              autres en mouvement et de les aider à trouver les solutions par
              eux-mêmes. Je suis passionnée par l’orientation d’individu, la
              formation et l’accompagnement des personnes dans leur recherche
              personnelle. Avoir un déclic, se mettre en mouvement et s’épanouir
              dans une voie professionnelle qui nous correspond, voilà tout le
              bien que je souhaite à chacun.
            </p>
            <p>Je suis certifiée coach ICF et orientatrice ennéagramme HPEI</p>
          </Grid>
        </Grid>
      </Section>
    </React.Fragment>
  )
}

QuiSommesNousPage.propTypes = {
  children: PropTypes.node,
}

export default QuiSommesNousPage
